<template>
  <div>
    <div v-if="checkMultiplePermissions(['demo.knowledge.items.simulateactivity', 'demo.knowledge.items.removeactivity'])" :class="{'add_border_bottom' : checkMultiplePermissions(['demo.knowledge.item.createsubmission', 'demo.knowledge.item.removesubmission', 'demo.knowledge.item.createfeedback', 'demo.knowledge.item.removefeedback'])}">
      <!-- Simulate Knowledge Item activity -->
      <CRow v-if="checkPermission('demo.knowledge.items.simulateactivity')" class="m-0">
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Simulate_demo_item_activity_for_company', { company_name: companyData.details.company_name })}}</label>
          <div class="d-flex align-items-center">
            <CButton color="primary" @click="simulateKnowledgeItemsActivity();" class="fit-content m-0">
              <span><i class="fa-solid fa-play mr-1"></i>{{$t('demo.Simulate_activity')}}</span>
            </CButton>
          </div>
        </CCol>
      </CRow>
      <!-- Remove Knowledge Item activity -->
      <CRow v-if="checkPermission('demo.knowledge.items.removeactivity')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeKnowledgeItemsActivity()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_activity')}}</span>
        </CCol>
      </CRow>
    </div>
    <div v-if="checkMultiplePermissions(['demo.knowledge.item.createsubmission', 'demo.knowledge.item.removesubmission'])" :class="{'add_border_bottom' : checkMultiplePermissions(['demo.knowledge.item.createfeedback', 'demo.knowledge.item.removefeedback'])}">
      <!-- Create Knowledge Item submission -->
      <CRow v-if="checkPermission('demo.knowledge.item.createsubmission')" class="m-0">
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Create_demo_submission_for_company', { company_name: companyData.details.company_name })}}</label>
          <div class="d-flex align-items-center">
            <CButton color="primary" @click="createDemoKnowledgeItemSubmission();" class="fit-content m-0">
              <span><i class="fa-solid fa-inbox mr-1"></i>{{$t('demo.Create_demo_submission')}}</span>
            </CButton>
          </div>
        </CCol>
      </CRow>
      <!-- Remove Knowledge Item submission -->
      <CRow v-if="checkPermission('demo.knowledge.item.removesubmission')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeDemoKnowledgeItemSubmission()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_demo_submission')}}</span>
        </CCol>
      </CRow>
    </div>
    <div v-if="checkMultiplePermissions(['demo.knowledge.item.createfeedback', 'demo.knowledge.item.removefeedback'])">   
      <!-- Create Knowledge Item feedback -->
      <CRow v-if="checkPermission('demo.knowledge.item.createfeedback')" class="m-0">
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Create_demo_feedback_for_company', { company_name: companyData.details.company_name })}}</label>
          <div class="d-flex align-items-center">
            <CButton color="primary" @click="createDemoKnowledgeItemFeedback();" class="fit-content m-0">
              <span><i class="fa-solid fa-headset mr-1"></i>{{$t('demo.Create_demo_feedback')}}</span>
            </CButton>
          </div>
        </CCol>
      </CRow>
      <!-- Remove Knowledge Item feedback -->
      <CRow v-if="checkPermission('demo.knowledge.item.removefeedback')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeDemoKnowledgeItemFeedback()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_feedback')}}</span>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Connect',
  props: ['companyData', 'platformPermissions'],  
  data() {
    return {}
  },
  methods: {
    simulateKnowledgeItemsActivity() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/knowledge/items/activity')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_activity_simulated'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeKnowledgeItemsActivity() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/knowledge/items/activity')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_activity_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },    
    createDemoKnowledgeItemSubmission() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/knowledge/items/submission')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_submission_created'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeDemoKnowledgeItemSubmission() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/knowledge/items/submission')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_submission_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },    
    createDemoKnowledgeItemFeedback() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/knowledge/items/feedback')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_feedback_created'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeDemoKnowledgeItemFeedback() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/knowledge/items/feedback')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_feedback_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },    
    checkMultiplePermissions(permissionTags){    
      var _this = this;
      var permissionsValue = 0;

      permissionTags.forEach(function(permission){        
        permissionsValue = permissionsValue + _this.platformPermissions.includes(permission);
      });

      return (permissionsValue > 0);
    }
  }
}
</script>