<template>
  <div class="m-auto">
    <div v-if="mode === 'auto'" class="spinner auto_mode d-table m-auto" v-bind:class="size">
      <div class="d-table-cell align-middle text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
        <p v-if="content" class="mt-2 mb-0"><b>{{content}}</b></p>
      </div>
    </div>
    <div v-if="mode === 'inline'" class="spinner inline_mode" v-bind:class="size">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>    
      <span v-if="content" class="ml-1">{{content}}</span>
    </div>    
  </div>
</template>

<script>

export default {
  name: 'loadingSpinner',
  props: ['mode', 'size', 'content'],
}
</script>

<style>

.spinner.inline_mode {
  display: flex;
  align-items: center;
}

.spinner .spinner-border {
  border-style: solid;
  border-color: var(--DashboardPrimary);
  border-right-color: transparent;
}

.spinner.auto_mode .spinner-border {  
  border-width: 0.4em;
  width: 50px;
  height: 50px;
}

.spinner.small .spinner-border {
  border-width: 0.3em;
  width: 25px;
  height: 25px;
}

.spinner.inline_mode .spinner-border {
  border-width: 0.25em;
  width: 15px;
  height: 15px;
}

.spinner p {
  font-size: 14px;
  text-transform: none;
}
</style>