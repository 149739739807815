<template>
  <CRow class="m-0 demo">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-tabs class="module_tabs no_borders mb-0" type="is-boxed" :animated="false" v-model="activeTab">
        <!-- Core -->
        <b-tab-item>
          <template slot="header">
            <span>{{$t('demo.Core')}}</span>
          </template>
          <template>
            <Core :companyData="companyData" :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <!-- Measure -->
        <b-tab-item v-if="demoUsersAvailable && modules.satisquestions == 1">
          <template slot="header">
            <span>{{$t('dash.measure')}}</span>
          </template>
          <template>
            <Measure :companyData="companyData" :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <!-- Loyalty -->
        <b-tab-item v-if="demoUsersAvailable && modules.employee_loyalty_programme == 1">
          <template slot="header">
            <span>{{$t('dash.loyalty')}}</span>
          </template>
          <template>
            <Loyalty :companyData="companyData" :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>                  
        <!-- Connect -->
        <b-tab-item v-if="demoUsersAvailable && modules.social_wall == 1">
          <template slot="header">
            <span>{{$t('dash.connect')}}</span>
          </template>
          <template>
            <Connect :companyData="companyData" :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <!-- Knowledge -->
        <b-tab-item v-if="demoUsersAvailable && modules.social_kb == 1">
          <template slot="header">
            <span>{{$t('dash.knowledge')}}</span>
          </template>
          <template>
            <Knowledge :companyData="companyData" :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <!-- Learn -->
        <b-tab-item v-if="demoUsersAvailable && modules.academy == 1">
          <template slot="header">
            <span>{{$t('dash.learn')}}</span>
          </template>
          <template>
            <Learn :companyData="companyData" :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <!-- Service -->
        <b-tab-item v-if="demoUsersAvailable && modules.service == 1">
          <template slot="header">
            <span>{{$t('dash.service')}}</span>
          </template>
          <template>
            <Service :companyData="companyData" :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>        
      </b-tabs>
    </CCol>
  </CRow>
</template>

<script>
import Core from '@/views/admin/company/demo/Core.vue';
import Measure from '@/views/admin/company/demo/Measure.vue';
import Loyalty from '@/views/admin/company/demo/Loyalty.vue';
import Connect from '@/views/admin/company/demo/Connect.vue';
import Knowledge from '@/views/admin/company/demo/Knowledge.vue';
import Learn from '@/views/admin/company/demo/Learn.vue';
import Service from '@/views/admin/company/demo/Service.vue';

export default {
  name: 'CompanyDemo',
  props: ['companyData', 'platformPermissions', 'modules'],
  components: {
    Core,
    Measure,
    Loyalty,
    Connect,
    Knowledge,
    Learn,
    Service
  },
  data() {
    return {      
      activeTab: 0,
      demoUsersAvailable: false
    }
  },
  mounted: function(){
    this.$bus.$on('demo_accounts_managed', (demoUsersAvailable) => {
      this.demoUsersAvailable = demoUsersAvailable;
    });    
  },
  beforeDestroy() {
    this.$bus.$off('demo_accounts_managed');
  }  
}
</script>