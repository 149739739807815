<template>
  <div>
    <div v-if="checkMultiplePermissions(['demo.measure.satisquestions.simulateanswers', 'demo.measure.satisquestions.removeanswers'])" :class="{'add_border_bottom' : checkMultiplePermissions(['demo.measure.burningmatters.simulate', 'demo.measure.burningmatters.remove'])}">
      <!-- Simulate SatisQuestion answers -->
      <CRow v-if="checkPermission('demo.measure.satisquestions.simulateanswers')" class="m-0" >
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Simulate_satisquestion_answers_for_company', { company_name: companyData.details.company_name, date_from: dateFromFormatted, date_to: dateToFormatted })}}</label>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1 mr-2">
              <b-datepicker v-model="dateFrom"
                            icon="calendar-day"
                            icon-pack="fas"
                            :first-day-of-week="1"
                            :show-week-number="true"
                            :max-date="new Date()"
                            :years-range="[-3, 10]"
                            :placeholder="$t('common.click_to_select')"
                            @input="setFormattedDateValues();">
                <CButton color="primary" @click="dateFrom = new Date(); setFormattedDateValues();">
                  <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
                </CButton>
              </b-datepicker>
            </div>
            <div class="flex-grow-1 mr-2">
              <b-datepicker v-model="dateTo"
                            icon="calendar-day"
                            icon-pack="fas"
                            :first-day-of-week="1"
                            :show-week-number="true"
                            :max-date="new Date()"
                            :years-range="[-3, 10]"
                            :placeholder="$t('common.click_to_select')"
                            @input="setFormattedDateValues();">
                <CButton color="primary" @click="dateTo = new Date(); setFormattedDateValues();">
                  <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
                </CButton>
              </b-datepicker>
            </div>
            <CButton color="primary" @click="simulateSatisQuestionsAnswers();" class="m-0" :disabled="dateFromFormatted === dateToFormatted">
              <span><i class="fa-solid fa-play mr-1"></i>{{$t('demo.Simulate_answers')}}</span>
            </CButton>
          </div>
        </CCol>
      </CRow>
      <!-- Remove SatisQuestion answers -->
      <CRow v-if="checkPermission('demo.measure.satisquestions.removeanswers')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeSatisQuestionsAnswers()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_answers')}}</span>
        </CCol>
      </CRow>
    </div>
    <div v-if="checkMultiplePermissions(['demo.measure.burningmatters.simulate', 'demo.measure.burningmatters.remove'])">
      <!-- Simulate Burning Matters -->
      <CRow v-if="checkPermission('demo.measure.burningmatters.simulate')" class="m-0">
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Create_burning_matters_for_company', { company_name: companyData.details.company_name })}}</label>
          <div class="d-flex align-items-center">
            <CButton color="primary" @click="simulateBurningMatters();" class="fit-content">
              <span><i class="fa-solid fa-play mr-1"></i>{{$t('demo.Create_burning_matters')}}</span>
            </CButton>        
          </div>
        </CCol>
      </CRow>
      <!-- Remove Burning Matters -->
      <CRow v-if="checkPermission('demo.measure.burningmatters.remove')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeBurningMatters()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_burning_matters')}}</span>
        </CCol>
      </CRow>               
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Connect',
  props: ['companyData', 'platformPermissions'],  
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      dateFromFormatted: null,
      dateToFormatted: null,
    }
  },
  methods: {
    simulateSatisQuestionsAnswers() {
      // Set the params
      let params = {};        
      params.date_from = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      params.date_to = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");

      // Simulate the SatisQuestion answers
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/measure/satisquestions/answers', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_answers_simulated'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeSatisQuestionsAnswers() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/measure/satisquestions/answers')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_answers_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },    
    simulateBurningMatters() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/measure/burning-matters')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_burning_matters_created'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeBurningMatters() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/measure/burning-matters')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_burning_matters_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkMultiplePermissions(permissionTags){    
      var _this = this;
      var permissionsValue = 0;

      permissionTags.forEach(function(permission){        
        permissionsValue = permissionsValue + _this.platformPermissions.includes(permission);
      });

      return (permissionsValue > 0);
    },    
    setFormattedDateValues() {
      this.dateFromFormatted = this.$luxon(this.dateFrom.toISOString(), "dd-MM-yyyy");
      this.dateToFormatted = this.$luxon(this.dateTo.toISOString(), "dd-MM-yyyy");
    }     
  },
  mounted: function(){
    // Set the default for dateFrom
    this.dateFrom = new Date();
    this.dateTo = new Date();
     // Set dateFrom to today minus 1 year
    this.dateFrom.setFullYear(this.dateFrom.getFullYear() - 1);
    // Set the formatted date values
    this.setFormattedDateValues();
  }  
}
</script>