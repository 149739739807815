<template>
  <div v-if="companyLoading === true">
    <CRow>
      <CCol cols="12" lg="12">
        <loadingSpinner mode="auto" :content="$t('admin.Loading_company_details')"/>
      </CCol>
    </CRow>      
  </div>
  <div v-else>
    <CRow class="m-0">
      <CCol cols="12" lg="12" class="pt-0 pb-0">
        <b-tabs class="module_tabs no_borders mb-0" type="is-boxed" :animated="false" v-model="activeTab">
          <!-- General -->
          <b-tab-item>
            <template slot="header">
              <span>{{$t('common.General')}}</span>
            </template>
            <template>
              <CRow class="m-0">                    
                <CCol md="3" lg="3" class="pb-0">
                  <CInput type="text" :label="$t('core.company_name')" class="mb-0" v-model="company.company_name" required was-validated/>                        
                </CCol>
                <CCol md="3" lg="3" class="pb-0">
                  <CInput type="text" :label="$t('core.brand_name')" class="mb-0" v-model="company.brand_name" required was-validated/>                        
                </CCol>
                <CCol md="6" lg="6" class="pb-0">
                  <label>{{$t('core.Company_based_in_country')}}</label>
                  <multiselect class="open_absolute"
                              v-model="company.company_country"
                              :options="countries" 
                              :multiple="false"
                              :hideSelected="false"
                              :closeOnSelect="true"
                              track-by="code" 
                              label="name"
                              :show-labels="false">
                    <span slot="noResult">{{$t('common.no_countries_found')}}</span>

                    <template slot="singleLabel" slot-scope="country">
                      <span v-if="country.option.emoji" class="language_flag" v-html="getTwemoji(country.option.emoji)"></span>
                      <span class="language_name">{{ country.option.name }}</span>
                    </template>

                    <template slot="option" slot-scope="country">
                      <span v-if="country.option.emoji" class="language_flag" v-html="getTwemoji(country.option.emoji)"></span>
                      <span class="language_name">{{ country.option.name }}</span>
                    </template>
                  </multiselect>
                </CCol>      
              </CRow>
              <CRow class="m-0">
                <CCol md="6" lg="6" class="pb-0">
                  <CInput type="text" :label="$t('core.Email_signatory')" class="mb-0" v-model="company.email_signatory" required was-validated/>
                </CCol>
                <CCol md="6" lg="6" class="pb-0">
                  <CInput type="text" :label="$t('core.Dashboard_title')" class="mb-0" v-model="company.dashboard_title" required was-validated/>                        
                </CCol>
              </CRow>
              <CRow class="m-0">
                <CCol md="6" lg="6" class="pb-0">
                  <CInput type="text" :label="$t('core.App_title')" class="mb-0" v-model="company.app_title" required was-validated/>                        
                </CCol>
                <CCol md="6" lg="6" class="pb-0">
                  <CInput type="text" :label="$t('core.Copyright')" class="mb-0" v-model="company.copyright" required was-validated/>                        
                </CCol>            
              </CRow>
              <CRow class="m-0">
                <CCol md="6" lg="6">
                  <label class="d-block">{{$t('core.Get_started_completed')}}</label>
                  <b-switch v-model="company.get_started_completed" class="m-0" size="is-small">
                    <span>{{company.get_started_completed ? $t('Yes') : $t('No')}}</span>
                  </b-switch>
                </CCol>       
              </CRow>
            </template>
          </b-tab-item>
          <!-- Dyntube -->
          <b-tab-item>
            <template slot="header">
              <span>{{$t('common.Dyntube')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="12" lg="12">        
                  <CInput type="text" :label="$t('common.Dyntube_project_id', { company_name : company.company_name })" class="mb-0" v-model="company.dyntube_project_id"/>
                </CCol>
              </CRow>
            </template>
          </b-tab-item>
          <!-- Hostnames -->
          <b-tab-item>
            <template slot="header">
              <span>{{$t('core.Hostnames')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pb-0">
                  <CInput type="text" :label="$t('core.Dashboard_hostname', { company_name : company.company_name })" class="mb-0" v-model="company.company_dashboard_hostname"/>
                  <span v-html="$t('core.Stick_with_environment_hostname', { environment_hostname : company.environment_dashboard_hostname })" class="meta"></span>
                </CCol>
                <CCol cols="12" lg="12">        
                  <CInput type="text" :label="$t('core.App_hostname', { company_name : company.company_name })" class="mb-0" v-model="company.company_app_hostname"/>
                  <span v-html="$t('core.Stick_with_environment_hostname', { environment_hostname : company.environment_app_hostname })" class="meta"></span>
                </CCol>      
              </CRow>
            </template>
          </b-tab-item>
          <!-- Native apps -->
          <b-tab-item>
            <template slot="header">
              <span>{{$t('core.Native_apps')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pb-0">
                  <span><i class="fa-brands fa-google-play mr-1"></i>{{$t('common.Android')}}</span>
                </CCol>
              </CRow>              
              <CRow class="m-0">
                <CCol cols="5" lg="5" md="12" sm="12" class="pb-0">
                  <CInput type="text" :label="$t('core.Android_deep_link_name', { company_name : company.company_name })" class="mb-0" v-model="company.company_android_deep_link_name"/>
                  <span v-html="$t('core.Stick_with_android_deep_link_name', { deep_link : company.environment_android_deep_link_name })" class="meta"></span>
                </CCol>
                <CCol cols="7" lg="7" md="12" sm="12" class="pb-0">
                  <CInput type="text" :label="$t('core.Android_store_url', { company_name : company.company_name })" class="mb-0" v-model="company.company_android_store_url"/>
                  <span v-html="$t('core.Stick_with_environment_store_url', { store_url : company.environment_android_store_url })" class="meta"></span>
                </CCol>      
              </CRow>
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pb-0">
                  <span><i class="fa-brands fa-app-store-ios mr-1"></i>{{$t('common.iOS')}}</span>
                </CCol>
              </CRow>                 
              <CRow class="m-0">
                <CCol cols="5" lg="5" md="12" sm="12" class="pb-0">
                  <CInput type="text" :label="$t('core.IOS_app_link_name', { company_name : company.company_name })" class="mb-0" v-model="company.company_ios_app_link_name"/>
                  <span v-html="$t('core.Stick_with_ios_app_link_name', { app_link : company.environment_ios_app_link_name })" class="meta"></span>
                </CCol>
                <CCol cols="7" lg="7" md="12" sm="12">
                  <CInput type="text" :label="$t('core.IOS_store_url', { company_name : company.company_name })" class="mb-0" v-model="company.company_ios_store_url"/>
                  <span v-html="$t('core.Stick_with_environment_store_url', { store_url : company.environment_ios_store_url })" class="meta"></span>
                </CCol>      
              </CRow>                
            </template>
          </b-tab-item>            
        </b-tabs>
      </CCol>
    </CRow>
    <hr class="m-0">
    <CRow class="m-0">
      <CCol cols="12" lg="12">
        <CButton color="primary" @click="updateAdminCompanyDetails()" class="m-0">
          <span><i class="fa-solid fa-save mr-1"></i>{{$t('admin.Update_company_details')}}</span>
        </CButton>        
      </CCol>
    </CRow>    
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Multiselect from 'vue-multiselect'

export default {
  name: 'CompanyDetails',
  props: ['countries'],
  components: {
    Multiselect,
    loadingSpinner
  },   
  data() {
    return {      
      activeTab: 0,
      company: {},
      companyLoading: false,
      companyLoaded: false
    }
  },
  methods: {
    getAdminCompanyDetails() {
      // Start the loader
      if(this.companyLoaded === false) this.companyLoading = true;
      // Get the company details      
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/company/details')
      .then(res => {      
        this.company = res.data.data;
        // Stop the loader
        this.companyLoading = false;
        // Update the companyLoaded value
        this.companyLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateAdminCompanyDetails() {
      // Set the params
      let params = {};
      params.companyDetailsData = this.company;
      params.companyDetailsData.get_started_completed = params.companyDetailsData.get_started_completed === true ? 1 : 0;      
      if(params.companyDetailsData.dyntube_project_id === "") params.companyDetailsData.dyntube_project_id = null;
      if(params.companyDetailsData.company_dashboard_hostname === "") params.companyDetailsData.company_dashboard_hostname = null;
      if(params.companyDetailsData.company_app_hostname === "") params.companyDetailsData.company_app_hostname = null;
      if(params.companyDetailsData.company_android_deep_link_name === "") params.companyDetailsData.company_android_deep_link_name = null;
      if(params.companyDetailsData.company_android_store_url === "") params.companyDetailsData.company_android_store_url = null;
      if(params.companyDetailsData.company_ios_app_link_name === "") params.companyDetailsData.company_ios_app_link_name = null;
      if(params.companyDetailsData.company_ios_store_url === "") params.companyDetailsData.company_ios_store_url = null;

      if(this.validateAdminCompanyDetails(params) === true) {
        // Save the details of the company
        axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/details', params)
        .then(res => { 
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('admin.Company_details_updated'), type: 'is-success', duration: 2000 });
          // Update the company details
          this.getAdminCompanyDetails();
        })
        .catch(err => {
          console.error(err);
        });
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    validateAdminCompanyDetails(params) {
      let company_name = params.companyDetailsData.company_name;
      let brand_name = params.companyDetailsData.brand_name;
      let company_country = params.companyDetailsData.company_country;
      let email_signatory = params.companyDetailsData.email_signatory;
      let dashboard_title = params.companyDetailsData.dashboard_title;
      let app_title = params.companyDetailsData.app_title;
      let copyright = params.companyDetailsData.copyright;

      // Check if base information for the company is available
      if(company_name && brand_name && company_country && email_signatory && dashboard_title && app_title && copyright) {
        return true;
      } else {
        return false;
      }
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },
  mounted: function() {
    this.getAdminCompanyDetails();
  }  
}
</script>