<template>
  <CCard class="user_profile_card">
    <CCardBody>
      <CRow>
        <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
          <center>
            <div v-if="userData.profile_image" class="profile_image mb-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + userData.user_id_external + '/' + userData.profile_image + '/' + clientToken + ')' }"></div>
            <div v-else class="profile_icon mb-2">
              <userProfileAvatar :username="userData.name" :size="40"/>
            </div>
          </center>
        </CCol>                      
        <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0 text-center">
          <div>
            <span v-line-clamp="1"><b>{{userData.name}}</b></span>            
          </div>
          <div>
            <span v-if="userData.team_name" v-line-clamp="1" class="meta">{{userData.team_name}} ({{userData.department_name}})</span>
          </div>
          <div>
            <span v-if="userData.function" v-line-clamp="1" class="meta">{{userData.function}}</span>
          </div>
        </CCol>                                                
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'userProfileCard',
  props: ['userData'],
  components: {
    userProfileAvatar
  },
    data() {
    return {
      apiBaseUrl: null,
      clientToken: null
    }
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>