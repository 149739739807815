<template>
  <div>
    <!-- Simulate Form submissions -->
    <CRow v-if="checkPermission('demo.service.form.simulatesubmissions')" class="m-0">
      <CCol cols="12" lg="12">
        <label>{{$t('demo.Create_form_submissions_for_company', { company_name: companyData.details.company_name })}}</label>
        <div class="d-flex align-items-center">
          <CInput type="number" step="1" min="0" class="flex-grow-1 mb-0" v-model="demoSubmissionAmount"/>
          <CButton color="primary" @click="createDemoServiceFormSubmissions();" class="ml-2 mr-0 fit-content" :disabled="demoSubmissionAmount <= 0">
            <span><i class="fa-solid fa-play mr-1"></i>{{$t('demo.Create_submissions')}}</span>
          </CButton>
        </div>
      </CCol>
    </CRow>
    <!-- Remove Form submissions -->
    <CRow v-if="checkPermission('demo.service.form.removesubmissions')" class="m-0">
      <CCol cols="12" lg="12" class="pt-0">
        <span @click="removeDemoServiceFormSubmissions()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_demo_form_submissions')}}</span>
      </CCol>
    </CRow>         
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Learn',
  props: ['companyData', 'platformPermissions'],  
  data() {
    return {
      demoSubmissionAmount: 0
    }
  },
  methods: {
    createDemoServiceFormSubmissions() {
      // Set the params
      let params = {};
      params.submission_amount = this.demoSubmissionAmount;      
      // Create the demo orders      
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/service/forms/submissions', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_form_submissions_created'), type: 'is-success', duration: 2000 });
        // Reset the demoSubmissionAmount
        this.demoSubmissionAmount = 0;        
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeDemoServiceFormSubmissions() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/service/forms/submissions')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_form_submissions_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },      
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  }
}
</script>