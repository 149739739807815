<template>
  <div v-if="companyEnvironmentLoaded === false || companyModulesLoaded === false">
    <CRow>
      <CCol cols="12" lg="12">
        <loadingSpinner mode="auto" :content="$t('admin.Loading_environment')"/>
      </CCol>
    </CRow>      
  </div>  
  <div v-else>
    <CRow class="m-0">
      <CCol cols="12" lg="12">
        <label><b>{{$t('admin.Set_company_environment')}}</b></label>
        <multiselect v-model="companyEnvironment"
                     label="environment_name" 
                     track-by="environment_id_external" 
                     :placeholder="$t('Search_for_an') + ' ' + $t('common.environment')"
                     open-direction="bottom" 
                     :options="environments" 
                     :multiple="false" 
                     :searchable="true" 
                     :loading="false" 
                     :internal-search="false"
                     :clear-on-select="true"
                     :close-on-select="true"
                     :options-limit="300"
                     :max-height="600"
                     :show-no-results="true"
                     :hide-selected="true"
                     @input="setCompanyEnvironment">
          <span slot="noResult">{{ $t('common.No_environments_found') }}</span>
          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>                
        </multiselect>      
      </CCol>
    </CRow>    
    <CRow class="m-0">
      <CCol cols="12" lg="12" class="pt-0 pb-0">
        <label class="m-0"><b>{{$t('admin.Manage_environment_products')}}</b></label>       
        <b-tabs class="module_tabs no_borders mb-0" type="is-boxed" :animated="false" v-model="activeProductTab">
          <b-tab-item v-for="(modules, product) in companyModules" v-bind:key="product">
            <template slot="header">
              <span>{{$t('dash.' + product)}}</span>
            </template>
            <template>
              <div v-if="companyProductsSetupLoaded && companyProductsWithSetup.includes(product) && companyProductsSetup[product] === 0">
                <SetUpCompanyProduct :companyData="companyData" :productTag="product"/>
              </div>
              <div class="product_modules">
                <CRow class="m-0">
                  <CCol cols="12" lg="12" class="pb-0">
                    <label><b>{{$t('admin.Set_environment_modules')}}</b></label>
                  </CCol>
                </CRow>                
                <CRow class="m-0">
                  <CCol v-for="(module, index) in companyModules[product]" v-bind:key="index" cols="4" lg="4" md="6" class="pt-0">
                    <b-switch class="mb-0 mt-1 mt-xl-0" v-model="module.active" size="is-small" @input="updateModuleStatus(module)">
                      <span class="module_name">{{ $t('modules.' + module.name)}}</span>
                    </b-switch>                    
                  </CCol>
                </CRow>
              </div>
              <div v-if="product === 'loyalty'">
                <LoyaltyStoreLocked :companyData="companyData"/>
              </div>
              <div v-if="product === 'core'">
                <EmailTestModeEnabled :companyData="companyData"/>
              </div>
            </template>
          </b-tab-item>
        </b-tabs>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import SetUpCompanyProduct from '@/views/admin/company/environment/SetUpCompanyProduct.vue';
import LoyaltyStoreLocked from '@/views/admin/company/environment/LoyaltyStoreLocked.vue';
import EmailTestModeEnabled from '@/views/admin/company/environment/EmailTestModeEnabled.vue';

export default {
  name: 'CompanyEnvironment',
  props: ['companyData', 'environments'],
  components: {
    Multiselect,
    SetUpCompanyProduct,
    loadingSpinner,
    LoyaltyStoreLocked,
    EmailTestModeEnabled
  },
  watch: {
    companyData: {
      handler() {
        if(this.companyData && this.companyData.details) {
          // Set the companyEnvironment
          this.companyEnvironment = {
            environment_id_external: this.companyData.details.environment_id_external,
            environment_name: this.companyData.details.environment_name,
            environment_tag: this.companyData.details.environment_tag
          }
          // Update the companyEnvironmentLoaded value
          this.companyEnvironmentLoaded = true;
        }
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      companyEnvironment: null,
      companyEnvironmentLoaded: false,      
      activeProductTab: 0,
      companyModules: {},
      companyModulesLoaded: false,
      companyProductsSetup: {},
      companyProductsSetupLoaded: false,
      companyProductsWithSetup: ['connect', 'loyalty', 'measure', 'knowledge', 'learn']
    }
  },
  methods: {
    setCompanyEnvironment(data) {
      let params = {};
      params.environment_id_external = data.environment_id_external;
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/environment', params)
      .then(res => {
        let environmentDetails = res.data.data;
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Company_environment_set'), type: 'is-success', duration: 1000 });
        // Update the environmentTag data in localStorage
        localStorage.setItem('environmentTag', environmentDetails.environment_tag);
        // Refresh the page
        window.location.reload();
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    updateModuleStatus(module) {
      let params = {};
      params.module_name = module.name;
      // Set the value of the module active param
      (module.active === true) ? params.module_active = 1 : params.module_active = 0;
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/modules/status', params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('core.Module_status_updated'), type: 'is-success', duration: 2000 });
        // Emit the module_status_updated event
        this.$bus.$emit('module_status_updated');        
      })
      .catch(err => {
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    getProductsSetup() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/products/setup')
      .then(res => {
        this.companyProductsSetup = res.data.data;
        // Update the companyProductsSetupLoaded value
        this.companyProductsSetupLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/modules/status')
      .then(res => {
        let products = res.data.data;
        // Reset the companyModules array
        this.resetCompanyModules();           
        // Loop through the products
        for(var product in products) {
          // Loop through the modules of the product
          for(var module in products[product]) {
            // Create a new companyModule object
            let companyModule = { name: module };
            // Set the active status of the module (value 1 = active, 0 = inactive)
            (products[product][module] === 1) ? companyModule.active = true : companyModule.active = false;
            // Add the module object to the modules array        
            this.companyModules[product].push(companyModule);
          }
        }
        // Update the companyModulesLoaded value
        this.companyModulesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    resetCompanyModules() {
      this.companyModules = {
        measure: [],
        connect: [],
        knowledge: [],
        learn: [],
        loyalty: [],
        service: [],
        hub: [],
        core: []
      }
    }
  },
  mounted: function(){
    this.getModules();
    this.getProductsSetup();

    this.$bus.$on('connect_set_up_successful', () => {      
      this.getProductsSetup();
    })

    this.$bus.$on('loyalty_set_up_successful', () => {      
      this.getProductsSetup();
    })

    this.$bus.$on('measure_set_up_successful', () => {      
      this.getProductsSetup();
    })

    this.$bus.$on('knowledge_set_up_successful', () => {      
      this.getProductsSetup();
    })

    this.$bus.$on('learn_set_up_successful', () => {      
      this.getProductsSetup();
    })    
  },
  beforeDestroy() {
    this.$bus.$off('connect_set_up_successful');
    this.$bus.$off('loyalty_set_up_successful');
    this.$bus.$off('measure_set_up_successful');
    this.$bus.$off('knowledge_set_up_successful');
    this.$bus.$off('learn_set_up_successful');    
  }
}
</script>
