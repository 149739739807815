<template>
  <div>
    <!-- Create accounts -->
    <CRow v-if="checkPermission('demo.core.createusers')" class="m-0">
      <CCol cols="12" lg="12">
        <label>{{$t('demo.Create_demo_accounts_for_company', { company_name : companyData.details.company_name })}}</label>
        <div class="d-flex align-items-center">
          <CInput type="number" step="1" min="0" class="flex-grow-1 mb-0 mr-2" v-model="demoUserAmount"/>
          <CButton color="primary" @click="createDemoUserAccounts();" class="m-0" :disabled="demoUserAmount <= 0">
            <span><i class="fa-solid fa-people-group mr-2"></i>{{$t('demo.Create_demo_accounts')}}</span>
          </CButton>
        </div>
      </CCol>
    </CRow>
    <!-- Remove accounts -->
    <CRow v-if="checkPermission('demo.core.removeusers')" class="m-0">
      <CCol cols="12" lg="12" class="pt-0">
        <span @click="removeDemoUserAccounts()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_demo_accounts')}}</span>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'General',
  props: ['companyData', 'platformPermissions'],  
  data() {
    return {
      demoUsers: [],
      demoUsersAvailable: false,
      demoUserAmount: 0
    }
  },
  methods: {
    getDemoUserAccounts() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/demo/core/users')
      .then(res => { 
        this.demoUsers = res.data.data.demo_users;
        this.demoUsersAvailable = res.data.data.demo_users_available;
        // Emit the demo_accounts_managed event 
        this.$bus.$emit('demo_accounts_managed', this.demoUsersAvailable);
      })
      .catch(err => {
        console.error(err);
      });
    },    
    createDemoUserAccounts() {
      // Set the params
      let params = {};
      params.user_amount = this.demoUserAmount;      
      // Create the demo accounts
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/core/users', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_accounts_created'), type: 'is-success', duration: 2000 });
        // Get the demo user accounts
        this.getDemoUserAccounts();
        // Reset the demoUserAmount
        this.demoUserAmount = 0;
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeDemoUserAccounts() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/core/users')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_accounts_removed'), type: 'is-success', duration: 2000 });
        // Get the demo user accounts
        this.getDemoUserAccounts();        
      })
      .catch(err => {
        console.error(err);
      });
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function() {
    this.getDemoUserAccounts();
  }
}
</script>