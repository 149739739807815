<template>
  <avatar :username="username" :size="size"></avatar>
</template>

<script>
import Avatar from 'vue-avatar'

export default {
  name: 'userProfileAvatar',
  props: ['username', 'size'],
  components: {
    Avatar
  }
}
</script>