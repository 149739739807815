<template>
  <div v-if="companyWkrDataLoading === true">
    <CRow>
      <CCol cols="12" lg="12">
        <loadingSpinner mode="auto" :content="$t('wkr.Loading_wkr_data')"/>
      </CCol>
    </CRow>      
  </div>
  <div v-else class="wkr">
    <CRow v-if="companyWkrDataLoaded && checkPermission('admin.company.wkrdata.add')" class="m-0">
      <CCol cols="12" xl="12" class="pb-0 text-left">
        <CButton class="btn_small" color="primary" @click="openSidebarRight('company_wkr_details', { loyalty_company_wkr_id_external: null });">    
          <span><i class="fas fa-plus mr-1"/>{{ $t('wkr.Add_wkr_data') }}</span>
        </CButton>
      </CCol>     
    </CRow>
    <CRow>
      <CCol cols="12" lg="12" class="pb-0">
        <b-table ref="wkrDataTable"
                 class="data_table"
                 :data="companyWkrData"
                 :striped="true"
                 :hoverable="true"
                 :mobile-cards="true"
                 :paginated="isPaginated"
                 :per-page="perPage"
                 :current-page.sync="currentPage"
                 :pagination-simple="isPaginationSimple"
                 :pagination-position="paginationPosition"
                 :total="totalItems"
                 backend-pagination
                 @page-change="onPageChange">
          
          <template slot-scope="props">
            <b-table-column field="year" :label="$t('common.Year')" :searchable="searchEnabled" width="7.5%">
              <span>{{props.row.year}}</span>
            </b-table-column>                                               
            <b-table-column field="fiscal_salary_sum" :label="$t('wkr.Fiscal_salary_sum')" :searchable="searchEnabled" width="12.5%">
              <span class="count d-block">&euro;{{formatToCurrency(props.row.fiscal_salary_sum)}}</span>
            </b-table-column>
            <b-table-column field="available_percentage" :label="$t('wkr.Available_percentage')" :searchable="searchEnabled" width="12.5%">
              <span>{{props.row.available_percentage}}%</span>
            </b-table-column>
            <b-table-column field="expected_mutations" :label="$t('wkr.Expected_mutations')" :searchable="searchEnabled" width="12.5%">
              <span>{{props.row.expected_mutations ? props.row.expected_mutations + ' FTE' : '-'}}</span>
            </b-table-column>
            <b-table-column field="comment" :label="$t('common.Comment')" :searchable="searchEnabled">
              <span>{{props.row.comment ? props.row.comment : '-'}}</span>
            </b-table-column>
            <b-table-column field="last_update" :label="$t('common.Last_update')" :searchable="searchEnabled" width="12.5%">
              <span>{{props.row.last_update | moment("DD-MM-YYYY HH:mm")}}</span>
            </b-table-column>
            <b-table-column field="wkr_data_approved" :label="$t('common.Approved')" class="wkr_data_approved" width="5%">
              <div class="d-flex align-items-center">
                <i class="fas" v-bind:class="{'fa-check' : props.row.wkr_data_approved, 'fa-times' : !props.row.wkr_data_approved}"></i>
              </div>                                                          
            </b-table-column> 
            <b-table-column field="wkr_budget_paid" :label="$t('common.Paid')" class="wkr_budget_paid" width="5%">
              <div class="d-flex align-items-center">
                <i class="fas" v-bind:class="{'fa-check' : props.row.wkr_budget_paid, 'fa-times' : !props.row.wkr_budget_paid}"></i>
              </div>                                                          
            </b-table-column>                                                     
            <b-table-column field="actions" label="" width="5%" :visible="checkPermission('admin.company.wkrdata.approve') || checkPermission('admin.company.wkrdata.edit') || checkPermission('admin.company.wkr.calculator')">
              <div class="d-flex justify-content-lg-end">
                <CDropdown color="primary" stoggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                  <div slot="toggler-content">
                    <span class="d-flex align-items-center">
                      {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                    </span>
                  </div>

                  <CDropdownItem v-if="checkPermission('admin.company.wkrdata.approve') && !props.row.wkr_data_approved">
                    <div class="d-flex align-items-center" @click="approveModal = true; approveModalData = props.row;">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-check"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('wkr.Approve_wkr_data')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>
                  <CDropdownItem v-if="checkPermission('admin.company.wkrdata.paymentstatus')">
                    <div class="d-flex align-items-center" @click="paymentStatusModal = true; paymentStatusModalData = props.row;">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas" v-bind:class="{'fa-check' : !props.row.wkr_budget_paid, 'fa-times' : props.row.wkr_budget_paid}"></i>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{!props.row.wkr_budget_paid ? $t('wkr.Mark_wkr_budget_paid') : $t('wkr.Mark_wkr_budget_unpaid')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>                  
                  <CDropdownItem v-if="checkPermission('admin.company.wkrdata.edit') && !props.row.wkr_data_approved">
                    <div class="d-flex align-items-center" @click="openSidebarRight('company_wkr_details', { loyalty_company_wkr_id_external: props.row.loyalty_company_wkr_id_external });">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-pen"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('wkr.Edit_wkr_data')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>
                  <CDropdownItem v-if="checkPermission('admin.company.wkr.calculator')">
                    <div class="d-flex align-items-center" @click="openWkrCalculationPage(props.row.loyalty_company_wkr_id_external);">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-calculator"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('wkr.Calculate_wkr_budget')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>                                                                                                   
                </CDropdown>
              </div>
            </b-table-column>                                      
          </template>
          <template slot="empty">
            <div class="text-center">
              <span>{{$t('wkr.No_wkr_data_available')}}</span>
            </div>                
          </template>                
        </b-table>
      </CCol>
    </CRow>

    <b-modal class="target pl-0 pr-0" :can-cancel="[]" :active.sync="approveModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">            
          {{ $t('common.Approve_marketplace_set')}} {{approveModalData.year}}
        </CCardHeader>
        <CCardBody>          
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('wkr.Are_you_sure_to_approve')}} {{approveModalData.year}}?</span>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="approveCompanyWkrData(approveModalData.loyalty_company_wkr_id_external);">
              <i class="fas fa-check mr-1"/>{{$t('wkr.Approve_wkr_data')}}
            </CButton>
            <CButton color="secondary" @click="approveModal = false;">
              <i class="fas fa-times mr-1"/>{{$t('Cancel')}}
            </CButton>
          </div>                  
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal class="target pl-0 pr-0" :can-cancel="[]" :active.sync="paymentStatusModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">                      
          <span v-if="paymentStatusModalData.wkr_budget_paid === false">{{$t('wkr.Mark_wkr_budget_paid')}}</span>
          <span v-else>{{$t('wkr.Mark_wkr_budget_unpaid')}}</span>
        </CCardHeader>
        <CCardBody>          
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span v-if="paymentStatusModalData.wkr_budget_paid === false">{{$t('wkr.Are_you_sure_to_mark_as_paid', { wkr_year: paymentStatusModalData.year })}}</span>
              <span v-else>{{$t('wkr.Are_you_sure_to_mark_as_unpaid', { wkr_year: paymentStatusModalData.year })}}</span>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="updateCompanyWkrPaymentStatus(paymentStatusModalData);">              
              <span v-if="paymentStatusModalData.wkr_budget_paid === false"><i class="fas fa-check mr-1"/>{{$t('wkr.Mark_wkr_budget_paid')}}</span>
              <span v-else><i class="fas fa-times mr-1"/>{{$t('wkr.Mark_wkr_budget_unpaid')}}</span>
            </CButton>
            <CButton color="secondary" @click="paymentStatusModal = false;">
              <i class="fas fa-times mr-1"/>{{$t('Cancel')}}
            </CButton>
          </div>                  
        </CCardFooter>
      </CCard>
    </b-modal>     
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'CompanyWKR',
  props: ['platformPermissions'],
  components: {
    loadingSpinner
  },
  data() {
    return {
      companyWkrData: [],
      companyWkrDataLoading: false,
      companyWkrDataLoaded: false,  
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true,      
      totalItems: 0,
      startIndex: 0,
      approveModal: false,
      approveModalData: {},
      paymentStatusModal: false,
      paymentStatusModalData: {}
    }
  },
  methods: {
    getCompanyWkrData() {
      // Start the loader
      if(this.companyWkrDataLoaded === false) this.companyWkrDataLoading = true;      
      // Get the WKR data
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/data/' + this.startIndex)
      .then(res => {              
        // Clear the companyWkrData array
        this.companyWkrData = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_wkr_data_items;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the wkr_data to the companyWkrData array
        this.companyWkrData = res.data.data.wkr_data;
        // Enable the pagination if necessary
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.companyWkrDataLoading = false;        
        // Stop the loader
        this.companyWkrDataLoaded = true;         
      })
      .catch(err => {
        console.error(err); 
      });
    },
    approveCompanyWkrData(companyWkrIdExternal) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/data/' + companyWkrIdExternal + '/approve')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('wkr.Company_wkr_data_approved'), type: 'is-success', duration: 2000 });

        if(this.$refs !== undefined && this.$refs.wkrDataTable !== undefined) {
          // Get the data index
          let dataIndex = this.$refs.wkrDataTable.data.findIndex(x => x.loyalty_company_wkr_id_external == companyWkrIdExternal);
          // Update the WKR data in the table
          this.$refs.wkrDataTable.data[dataIndex].wkr_data_approved = true;
        }
        // Close the approve modal
        this.approveModal = false;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    updateCompanyWkrPaymentStatus(paymentStatusModalData) {
      let params = {};
      (paymentStatusModalData.wkr_budget_paid === false) ? params.payment_status = 'Y' : params.payment_status = 'N';
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/data/' + paymentStatusModalData.loyalty_company_wkr_id_external + '/payment/status', params)
      .then(res => {
        if(params.payment_status === 'Y') {
          this.$buefy.toast.open({ message: this.$t('wkr.Marked_wkr_budget_paid', { wkr_year: paymentStatusModalData.year }), type: 'is-success', duration: 2000 });
        } else {
          this.$buefy.toast.open({ message: this.$t('wkr.Marked_wkr_budget_unpaid', { wkr_year: paymentStatusModalData.year }), type: 'is-success', duration: 2000 });
        }

        if(this.$refs !== undefined && this.$refs.wkrDataTable !== undefined) {
          // Get the data index
          let dataIndex = this.$refs.wkrDataTable.data.findIndex(x => x.loyalty_company_wkr_id_external == paymentStatusModalData.loyalty_company_wkr_id_external);
          // Update the WKR data in the table
          if(params.payment_status === 'Y') {
            this.$refs.wkrDataTable.data[dataIndex].wkr_budget_paid = true;
          } else {
            this.$refs.wkrDataTable.data[dataIndex].wkr_budget_paid = false;
          }
        }
        // Close the payment status modal
        this.paymentStatusModal = false;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    onPageChange(page) {
      // Set the startIndex value
      (page > 1) ? this.startIndex = (page - 1)*10 : this.startIndex = 0;
      // Get the company WKR data
      this.getCompanyWkrData();
    },
    openWkrCalculationPage(companyWkrIdExternal) {
      this.$router.push({path: '/admin/wkr/' + companyWkrIdExternal + '/calculator'});
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    formatToCurrency(data) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0 }).format(parseInt(data));
    }   
  },
  mounted: function() {
    this.getCompanyWkrData();

    this.$bus.$on('update_company_wkr_data', () => {      
      this.getCompanyWkrData();
    })    
  },
  beforeDestroy() {
    this.$bus.$off('update_company_wkr_data');
  }  
}
</script>