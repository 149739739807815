<template>
  <div>
    <!-- Create Onboarding packge -->
    <CRow v-if="checkPermission('demo.learn.onboarding.createpackage')" class="m-0">
      <CCol cols="12" lg="12">
        <label>{{$t('demo.Create_demo_onboarding_package_for_company', { company_name: companyData.details.company_name })}}</label>
        <div class="d-flex align-items-center">
          <CButton color="primary" @click="createDemoLearnOnboardingPackage();" class="fit-content m-0">
            <span><i class="fa-solid fa-play mr-1"></i>{{$t('demo.Create_demo_onboarding_package')}}</span>
          </CButton>
        </div>
      </CCol>
    </CRow>
    <!-- Remove Onboarding packge -->
    <CRow v-if="checkPermission('demo.learn.onboarding.removepackage')" class="m-0">
      <CCol cols="12" lg="12" class="pt-0">
        <span @click="removeDemoLearnOnboardingPackage()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_onboarding_package')}}</span>
      </CCol>
    </CRow>              
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Learn',
  props: ['companyData', 'platformPermissions'],  
  data() {
    return {}
  },
  methods: {
    createDemoLearnOnboardingPackage() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/learn/onboarding/package')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_onboarding_package_created'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeDemoLearnOnboardingPackage() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/learn/onboarding/package')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_onboarding_package_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },       
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  }
}
</script>