<template>
  <CRow class="no_permission">
    <CCol cols="12" xl="12" lg="12" md="12" sm="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <span>{{$t('common.No_permission')}}</span>
        </CCardHeader>
        <CCardBody>
          <span v-if="trigger === 'permission'">{{$t('common.Your_current_role_has_no_permission')}}</span>
          <span v-if="trigger === 'payment_status'">{{$t('common.Your_organization_has_no_permission')}}</span>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: 'noPermission',
  props: ['trigger']
}
</script>