<template>
  <div>
    <div v-if="checkMultiplePermissions(['demo.connect.createposts', 'demo.connect.removeposts'])" :class="{'add_border_bottom' : checkMultiplePermissions(['demo.connect.posts.simulateactivity', 'demo.connect.posts.removeactivity'])}">
      <!-- Create Connect Post -->
      <CRow v-if="checkPermission('demo.connect.createposts')" class="m-0">
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Create_demo_posts_for_company', { company_name: companyData.details.company_name })}}</label>
          <div class="d-flex align-items-center">
            <CInput type="number" step="1" min="0" class="flex-grow-1 mb-0" v-model="demoPostAmount"/>
            <CButton color="primary" @click="createDemoConnectPosts();" class="ml-2 mr-0" :disabled="demoPostAmount <= 0">
              <span><i class="fa-solid fa-message mr-2"></i>{{$t('demo.Create_demo_posts')}}</span>
            </CButton>
          </div>
        </CCol>
      </CRow>
      <!-- Remove Connect Posts -->
      <CRow v-if="checkPermission('demo.connect.removeposts')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeDemoConnectPosts()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_demo_posts')}}</span>
        </CCol>
      </CRow>    
    </div>
    <div v-if="checkMultiplePermissions(['demo.connect.posts.simulateactivity', 'demo.connect.posts.removeactivity'])">
      <!-- Simulate Connect Post activity -->
      <CRow v-if="checkPermission('demo.connect.posts.simulateactivity')" class="m-0">
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Simulate_demo_post_activity_for_company', { company_name: companyData.details.company_name })}}</label>
          <div class="d-flex align-items-center">
            <CButton color="primary" @click="simulateDemoConnectPostsActivity();" class="fit-content m-0">
              <span><i class="fa-solid fa-play mr-1"></i>{{$t('demo.Simulate_activity')}}</span>
            </CButton>
          </div>
        </CCol>
      </CRow>
      <!-- Remove Connect Post activity -->
      <CRow v-if="checkPermission('demo.connect.posts.removeactivity')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeDemoConnectPostsActivity()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_activity')}}</span>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Connect',
  props: ['companyData', 'platformPermissions'],  
  data() {
    return {
      demoPostAmount: 0
    }
  },
  methods: {
    createDemoConnectPosts() {
      // Set the params
      let params = {};
      params.post_amount = this.demoPostAmount;      
      // Create the demo posts
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/connect/posts', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_posts_created'), type: 'is-success', duration: 2000 });
        // Reset the demoPostAmount
        this.demoPostAmount = 0;
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeDemoConnectPosts() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/connect/posts')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_posts_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },    
    simulateDemoConnectPostsActivity() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/connect/posts/activity')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_activity_simulated'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeDemoConnectPostsActivity() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/connect/posts/activity')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_activity_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkMultiplePermissions(permissionTags){    
      var _this = this;
      var permissionsValue = 0;

      permissionTags.forEach(function(permission){        
        permissionsValue = permissionsValue + _this.platformPermissions.includes(permission);
      });

      return (permissionsValue > 0);
    }    
  }
}
</script>