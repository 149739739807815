<template>
  <div v-if="headerCustomizationsLoaded === false">
    <CRow>
      <CCol cols="12" lg="12">
        <loadingSpinner mode="auto" :content="$t('admin.Loading_headers')"/>
      </CCol>
    </CRow>      
  </div>  
  <div v-else class="custom_headers">    
    <CRow class="m-0">
      <CCol cols="6" lg="6" class="pb-0">
        <label><b>{{$t('admin.Custom_header_style')}}</b></label>
        <CTextarea class="m-0" v-model="headerCustomizations.custom_header_style" rows="10"/>
      </CCol>
      <CCol cols="6" lg="6" class="pb-0">
        <label><b>{{$t('admin.Custom_header_script')}}</b></label>
        <CTextarea class="m-0" v-model="headerCustomizations.custom_header_script" rows="10"/>
      </CCol>    
    </CRow>
    <CRow class="m-0">
      <CCol cols="12" lg="12">
        <CButton color="primary" @click="updateCompanyCustomHeaders()" class="m-0">
          <span><i class="fa-solid fa-save mr-1"></i>{{$t('admin.Update_header_customizations')}}</span>
        </CButton>        
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'CompanyHeaderCustomizations',
  props: ['companyData'],
  watch: {
    companyData: {
      handler() {
        if(this.companyData && this.companyData.header_customizations) {
          // Set the headerCustomizations
          this.headerCustomizations = this.companyData.header_customizations;
          // Update the headerCustomizationsLoaded value
          this.headerCustomizationsLoaded = true;
        }
      },
      deep: true,
      immediate: true
    }
  },  
  components: {
    loadingSpinner
  },
  data() {
    return {      
      headerCustomizations: {
        custom_header_style: null,
        custom_header_script: null
      },
      headerCustomizationsLoaded: false
    }
  },
  methods: {
    updateCompanyCustomHeaders() {
      let params = {};
      params.headerCustomizations = this.headerCustomizations;
      if(params.headerCustomizations.custom_header_style === '') params.headerCustomizations.custom_header_style = null;
      if(params.headerCustomizations.custom_header_script === '') params.headerCustomizations.custom_header_script = null;

      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/header/customizations', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('admin.Header_customizations_updated'), type: 'is-success', duration: 2000 });        
      })
      .catch(err => {
        console.error(err); 
      });
    }  
  }
}
</script>