<template>
  <div>
    <CRow class="m-0">
      <CCol cols="12" lg="12" class="pb-0">
        <label><b>{{$t('admin.Name_of_new_company')}}</b></label>
        <CInput type="text" class="m-0" v-model="newCompany.company_name" required was-validated/>
      </CCol>
    </CRow>    
    <CRow class="m-0">
      <CCol cols="12" lg="12" class="pb-0">
        <label><b>{{$t('admin.Environment_for_new_company')}}</b></label>
        <multiselect v-model="newCompany.environment"
                     label="environment_name" 
                     track-by="environment_id_external" 
                     :placeholder="$t('Search_for_an') + ' ' + $t('common.environment')"
                     open-direction="bottom" 
                     :options="environments" 
                     :multiple="false" 
                     :searchable="true" 
                     :loading="false" 
                     :internal-search="false"
                     :clear-on-select="true"
                     :close-on-select="true"
                     :options-limit="300"
                     :max-height="600"
                     :show-no-results="true"
                     :hide-selected="true">
          <span slot="noResult">{{ $t('common.No_environments_found') }}</span>
          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>                
        </multiselect>      
      </CCol>
    </CRow>
    <CRow class="m-0">
      <CCol cols="12" lg="12">
        <CButton class="m-0" color="primary" @click="confirmModal = true;" :disabled="!newCompany.company_name || !newCompany.environment">
          <span><i class="fas fa-plus mr-1"/>{{ $t('admin.Create_new_company') }}</span>
        </CButton>        
      </CCol>
    </CRow>

    <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
      <CCard v-if="newCompany.company_name && newCompany.environment" class="mb-0">
        <CCardHeader class="pb-0">
          <span>{{$t('admin.Confirm_new_company', { company_name : newCompany.company_name })}}</span>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="mb-2">
                <span>{{$t('admin.Are_you_sure_to_create_new_company')}}</span>
              </div>
              <div class="d-flex flex-column">
                <span><strong>{{$t('common.Company_name')}}: </strong>{{newCompany.company_name}}</span>
                <span><strong>{{$t('common.Environment')}}: </strong>{{newCompany.environment.environment_name}}</span>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="confirmModal = false; createNewCompany();"><i class="fas fa-check mr-1"/>{{$t('admin.Create_company')}}</CButton>
          <CButton color="secondary" @click="confirmModal = false;"><i class="fas fa-times mr-1"/>{{ $t('Cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>          
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';

export default {
  name: 'NewCompany',
  props: ['environments'],
  components: {
    Multiselect
  },  
  data() {
    return {
      newCompany: {
        company_name: null,
        environment: null
      },
      confirmModal: false
    }
  },
  methods: {
    createNewCompany() {
      let params = {};
      params.newCompanyData = this.newCompany;
      // Create the new company
      axios.post(process.env.VUE_APP_API_URL + '/v1/superadmin/setup/company', params)
      .then(res => {
        let newCompanyIdExternal = res.data.data.company_id_external;
        let newCompanyName = res.data.data.company_name;
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Company_created', { company_name : newCompanyName }), type: 'is-success', duration: 1000 });
        // Reset the new company data
        this.resetNewCompanyData();        
        // Switch to the newly created company
        this.setAdminCompany(newCompanyIdExternal);
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    setAdminCompany(companyIdExternal) {
      let params = {};
      params.company_id_external = companyIdExternal;
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/impersonate', params)
      .then(res => {
        let newCompanyDetails = res.data.data;
        // Update the data in localStorage
        this.updateLocalStorageData(newCompanyDetails);                
        // Refresh the page
        window.location.reload();
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    updateLocalStorageData(data) {
      // Clear items from localStorage for better transition between companies
      localStorage.removeItem('companyImageId');
      localStorage.removeItem('headerImageId');
      localStorage.removeItem('environmentTag');
      // Update the companyIdExternal data in localStorage
      localStorage.setItem('companyIdExternal', data.company_id_external);
      // Update the companyImageId data in localStorage
      if(data.company_image_id) localStorage.setItem('companyImageId', data.company_image_id);
      // Update the headerImageId data in localStorage
      if(data.header_image_id) localStorage.setItem('headerImageId', data.header_image_id); 
      // Update the environmentTag data in localStorage
      if(data.environment_tag) localStorage.setItem('environmentTag', data.environment_tag);
      // Remove the organizationViewToActivate data from localStorage
      localStorage.removeItem('organizationViewToActivate');      
      // Remove the connectChatToActivate data from localStorage
      localStorage.removeItem('connectChatToActivate');
      // Remove the activeFormCategory data from localStorage
      localStorage.removeItem('activeFormCategory');
      // Remove the serviceQuestionToActivate data from localStorage
      localStorage.removeItem('serviceQuestionToActivate');      
      // Remove the serviceQuestionEmployeeFilter data from localStorage
      localStorage.removeItem('serviceQuestionEmployeeFilter');
      // Remove the serviceQuestionFolderFilter data from localStorage
      localStorage.removeItem('serviceQuestionFolderFilter');
    },
    resetNewCompanyData() {
      this.newCompany = {
        company_name: null,
        environment: null
      }
    }
  },
  mounted: function(){
    this.resetNewCompanyData();
  }
}
</script>
